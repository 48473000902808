<template>
  <div>
    <!--表格-->
    <Table row-key="id" :tooltip-theme="$store.state.theme" :height="tableHeight" :loading="loading"
      :columns="tableColumns" :data="tableData" stripe border></Table>

    <!-- 分页 -->
    <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
      @on-page-size-change="handlePageSize" style="float: right;" />
  </div>
</template>

<script>
import tableHeightMixin from '_m/tableHeight.mixin'
import tablePageMixin from '_m/tablePage.mixin'

export default {
  name: 'Merchant',
  mixins: [tableHeightMixin, tablePageMixin],
  data () {
    return {
      tableData: [],
      currentMerchantItem: null,
    }
  },
  created () {
    this.initData()
  },
  computed: {
    tableColumns () {
      let columns = []
      columns.push({
        title: 'name',
        tree: true,
        key: 'name',
        align: 'center',
      })
      columns.push({
        title: 'appId',
        tree: true,
        key: 'appId',
        align: 'center',
      })
    columns.push({
      title: '扫码规则前缀',
      tree: true,
      key: 'qrcodeDomain',
      align: 'center',
    })
        columns.push({
          title: '归属商家',
          tree: true,
          key: 'merchant.name',
          align: 'center',
            render: (h, params) => {
              let value = ''
              const merchant = params.row.merchant
              if (merchant != null) {
                  value = merchant.name
              }
              return h('div', {
              }, value)
            }
        })
    columns.push({
      title: '支付账号',
      tree: true,
      key: 'payAccount.kdMchId',
      align: 'center',
      render: (h, params) => {
        const state = params.row.payAccount.kdMchId
        return h('div', {
        }, state)
      }
    })
    columns.push({
      title: '充值总额',
      tree: true,
      key: 'depositAmount',
      align: 'center',
    })
    columns.push({
      title: '提现总额',
      tree: true,
      key: 'withdrawAmount',
      align: 'center',
    })
    columns.push({
      title: '余额总额',
      tree: true,
      key: 'balanceAmount',
      align: 'center',
    })
      columns.push({
        title: '状态',
        key: 'status',
        align: 'center',
      render: (h, params) => {
        const state = params.row.status
        let a = '正常'
        if (state !== 0) {
            a = '禁用'
        }
        return h('div', {
        }, a)
      }
      })

      columns.push({
        title: '结算状态',
        key: 'amountStatus',
        align: 'center',
          render: (h, params) => {
            const state = params.row.amountStatus
            let a = '创建'
            if (state === 0) {
                a = '创建'
            } else if (state === 1) {
              a = '统计中'
            } else if (state === 2) {
             a = '账目异常'
            } else if (state === 3) {
               a = '账目一致'
            }
            return h('div', {
            }, a)
          }
      })
      return columns
    }
  },
  methods: {
    initData () {
      this.loading = true
      this.$http.post(`/portal/v1/wechat/m/findListByPage?page=${this.currentPage}&pageCount=${this.pageSize}`, {
          status: 0
      }).then((response) => {
        const { code, data } = response
        if (code === 1) {
            this.tableData = data.records
            this.total = data.total
        } else {
          this.$Notice.error({
            title: `接口报错:code=${code}`,
            desc: msg
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSuccess () {
      this.initData()
    }
  },
}
</script>
